import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';
import { AppRoutes } from '../enum/routes.enum';
import { environment } from 'src/environments/environment';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'dashboard',
    title: 'dashboard',
    icon: SoctripIcons.BAR_CHART_SQUARE_01,
    visible: true,
  },
  {
    path: 'agency-management',
    title: 'agency-management',
    icon: SoctripIcons.USER_CHECK_01,
    visible: true,
  },
  {
    path: AppRoutes.CAR_MANAGEMENT,
    title: AppRoutes.CAR_MANAGEMENT,
    icon: 'sctr-icon-car',
    visible: environment.CAR_MANAGEMENT_VISIBLE,
  },
  {
    path: AppRoutes.PROMOTION_MANAGEMENT,
    title: AppRoutes.PROMOTION_MANAGEMENT,
    icon: 'sctr-icon-percent-03',
    visible: environment.PROMOTION_MANAGEMENT_VISIBLE,
  },
  {
    path: AppRoutes.ROUTE_MANAGEMENT,
    title: AppRoutes.ROUTE_MANAGEMENT,
    icon: 'sctr-icon-route',
    visible: environment.ROUTE_MANAGEMENT_VISIBLE,
  },
  {
    path: 'booking-helpdesk',
    title: 'booking-helpdesk',
    icon: SoctripIcons.FILE_SEARCH_02,
    visible: true,
  },
  {
    path: `${AppRoutes.REFUND_MANAGEMENT}`,
    title: 'refund-management',
    icon: SoctripIcons.CREDIT_CARD_REFRESH,
    visible: true,
  },
  {
    path: `${AppRoutes.VOUCHER_MANAGEMENT}`,
    title: 'voucher-management',
    icon: SoctripIcons.TICKET_01,
    visible: true,
  },
  {
    path: `${AppRoutes.AUTOMAKER_MANAGEMENT}`,
    title: 'automaker-management',
    icon: SoctripIcons.CAR_01,
    visible: true,
  },
  {
    path: `${AppRoutes.AMENITY_MANAGEMENT}`,
    title: 'amenity-management',
    icon: SoctripIcons.TOOL_02,
    visible: true,
  },
  {
    path: AppRoutes.BANNER_MANAGERMENT,
    title: AppRoutes.BANNER_MANAGERMENT,
    icon: SoctripIcons.IMAGE_INDENT_LEFT,
    visible: true,
  },
  {
    path: AppRoutes.CHAT_FAQ_ASSISTANT,
    title: AppRoutes.CHAT_FAQ_ASSISTANT,
    icon: SoctripIcons.MESSAGE_QUESTION_SQUARE,
    visible: environment.SOCCHAT_VISIBLE,
  },
  {
    path: AppRoutes.ADMIN_SUPPORT,
    title: 'soctrip-chat',
    icon: SoctripIcons.MESSAGE_CHAT_SQUARE,
    visible: environment.SOCCHAT_VISIBLE,
  },
  {
    path: AppRoutes.RSS_FEED,
    title: AppRoutes.RSS_FEED,
    icon: 'sctr-icon-rss-01',
    visible: true,
  },
  {
    title: AppRoutes.CONFIGURATION,
    icon: 'sctr-icon-settings-01',
    visible: environment.CONFIGURATION_VISILE,
    children: [
      {
        path: AppRoutes.LANDING_PAGE_CONFIG,
        title: AppRoutes.LANDING_PAGE_CONFIG,
        icon: '',
        visible: environment.LANDING_PAGE_VISIBLE,
      },
      {
        path: 'rental-purpose',
        title: 'rental-purpose',
        visible: true,
      },
      {
        path: 'public-holiday',
        title: 'public-holiday',
        visible: true,
      },
      {
        path: 'seat-capacity',
        title: 'seat-capacity',
        visible: true,
      },
    ],
  },
];
