import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlagsDropdownComponent } from './components/dropdowns/flags-dropdown/flags-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { CoreModule } from '../core/core.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { CustomImageComponent } from './components/custom-image/custom-image.component';
import { SidebarModule } from 'primeng/sidebar';
import { HeaderComponent } from './components/header/header.component';
import { Countries } from '../core/models/classes/countries';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { TreeSelectModule } from 'primeng/treeselect';
import { InputTextModule } from 'primeng/inputtext';
import { SentenceCasePipe } from '../core/pipes/sentence-case.pipe';
import { ToastModule } from 'primeng/toast';
import { SkeletonModule } from 'primeng/skeleton';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { TabViewComponent } from './components/tabview/tabview.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DragAndDropDirective } from '../core/directives/drag-and-drop.directive';
import { AlphaNumericDirective } from '../core/directives/alpha-numeric.directive';
import { SwiperDirective } from '../core/directives/swiper.directive';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PasswordModule } from 'primeng/password';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarFilterComponent } from './components/calendar-filter/calendar-filter.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TreeChildComponent } from './components/tree-child/tree-child.component';
import { SafePipe } from './pipe/safe-url.pipe';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { StatusOrderDirective } from '../features/booking-management/directives/status-order.directive';
import { CarRentalLocationComponent } from './components/car-rental-location/car-rental-location.component';
import { TimelineModule } from 'primeng/timeline';
import { HistoryCurrencyPipe } from '@soctrip-common/app-security';
import { ImagePathPipe } from './pipe/image-path.pipe';
import { SearchingPlaceControllerService } from '@assistant/angular-map-location-service';
import { ResultNotFoundComponent } from './components/result-not-found/result-not-found.component';
import { PaginationComponent } from './components/pagination/pagination.component';

@NgModule({
  declarations: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    CustomImageComponent,
    HeaderComponent,
    DragAndDropDirective,
    PaginatorComponent,
    ToastMessageComponent,
    AlphaNumericDirective,
    TabViewComponent,
    TableEmptyComponent,
    CalendarComponent,
    SwiperDirective,
    AdminLayoutComponent,
    CalendarFilterComponent,
    TreeChildComponent,
    SafePipe,
    ImagePathPipe,
    CarRentalLocationComponent,
    PaginationComponent,
    ResultNotFoundComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    FormsModule,
    CoreModule,
    FileUploadModule,
    ImageModule,
    SidebarModule,
    TableModule,
    DialogModule,
    PaginatorModule,
    TreeSelectModule,
    InputTextModule,
    TranslateModule,
    ToastModule,
    SkeletonModule,
    DialogModule,
    CalendarModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    PasswordModule,
    ConfirmDialogModule,
    DragDropModule,
    CdkAccordionModule,
    AccordionModule,
    ProgressSpinnerModule,
    BadgeModule,
    TooltipModule,
    StatusOrderDirective,
    TimelineModule,
    HistoryCurrencyPipe,
  ],
  exports: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    CustomImageComponent,
    HeaderComponent,
    TranslateModule,
    DragAndDropDirective,
    SentenceCasePipe,
    PaginatorComponent,
    ToastMessageComponent,
    AlphaNumericDirective,
    TabViewComponent,
    TableEmptyComponent,
    CalendarComponent,
    SwiperDirective,
    CalendarFilterComponent,
    TreeChildComponent,
    SafePipe,
    ImagePathPipe,
    StatusOrderDirective,
    CarRentalLocationComponent,
    HistoryCurrencyPipe,
    PaginationComponent,
    ResultNotFoundComponent,
  ],
  providers: [Countries, SearchingPlaceControllerService],
})
export class SharedModule {}
