import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DropdownModule } from 'primeng/dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { SharedModule } from './shared/shared.module';

import { environment } from 'src/environments/environment';
import { Locales } from './core/constants/locales.config';
import { BookingManagementModule } from './features/booking-management/booking-management.module';

import {
  ApiModule as AnalyticApiModule,
  BASE_PATH as ANALYTIC_PATH,
} from '@soctrip/angular-shop-analytic-service';

import {
  ApiModule as CarRentalOtaApiModule,
  BASE_PATH as CAR_RENTAL_OTA_PATH,
} from '@car-rental/angular-car-rental-ota-service';

import {
  ApiModule as CarRentalOrderApiModule,
  BASE_PATH as CAR_RENTAL_ORDER_PATH,
} from '@car-rental/angular-car-rental-order-service';

import {
  ApiModule as ShipmentApiModule,
  BASE_PATH as SHIPMENT_PATH,
} from '@soctrip/angular-shipment-service';

import {
  ApiModule as CarRentalApiModule,
  BASE_PATH as CAR_RENTAL_PATH,
} from '@car-rental/angular-car-rental-service';

import {
  ApiModule as MapLocationApiModule,
  BASE_PATH as MAP_LOCATION_PATH,
} from '@assistant/angular-map-location-service';

import {
  ApiModule as RegionalApiModule,
  BASE_PATH as REGIONAL_PATH,
} from '@soctrip/angular-regional-service';
import {
  ApiModule as CarRentalPromotionApiModule,
  BASE_PATH as CAR_RENTAL_PROMOTION_PATH,
} from '@car-rental/angular-car-rental-promotion-service';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { LANGUAGE } from './core/enum/language.enum';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DevelopingComponent } from './features/developing/developing.component';
import { PublicHolidayComponent } from './features/public-holiday/public-holiday.component';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const enum ServiceName {
  ANALYTIC = 'ANALYTIC',
  CAR_RENTAL_OTA = 'CAR_RENTAL_OTA',
  CAR_RENTAL_ORDER = 'CAR_RENTAL_ORDER',
  SHIPMENT = 'SHIPMENT',
  CAR_RENTAL = 'CAR_RENTAL',
  REGIONAL = 'REGIONAL',
  MAP_LOCATION = 'MAP_LOCATION',
  CAR_RENTAL_PROMOTION = 'CAR-RENTAL-PROMOTION',
}

@NgModule({
  declarations: [AppComponent, DevelopingComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DropdownModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AnalyticApiModule,
    CarRentalOtaApiModule,
    CarRentalOrderApiModule,
    ShipmentApiModule,
    CarRentalOrderApiModule,
    CarRentalApiModule,
    RegionalApiModule,
    CarRentalPromotionApiModule,
    ToastModule,
    TranslateModule.forRoot({
      defaultLanguage: LANGUAGE.EN,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  providers: [
    {
      provide: ANALYTIC_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['ANALYTIC']}`,
    },
    {
      provide: CAR_RENTAL_OTA_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.['CAR_RENTAL_OTA']
      }`,
    },
    {
      provide: CAR_RENTAL_ORDER_PATH,
      useValue: `${
        environment.BE_URL +
        environment.SERVICES?.[ServiceName.CAR_RENTAL_ORDER]
      }`,
    },
    {
      provide: SHIPMENT_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['SHIPMENT']}`,
    },
    {
      provide: CAR_RENTAL_ORDER_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.['CAR_RENTAL_ORDER']
      }`,
    },
    {
      provide: CAR_RENTAL_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.CAR_RENTAL]
      }`,
    },
    {
      provide: MAP_LOCATION_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.MAP_LOCATION]
      }`,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: REGIONAL_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['REGIONAL']}`,
    },
    {
      provide: CAR_RENTAL_PROMOTION_PATH,
      useValue: `${
        environment.BE_URL + 'car-rental-promotion'
        // environment.SERVICES?.[ServiceName.CAR_RENTAL_PROMOTION]
      }`,
    },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.initI18n();
  }
  private initI18n() {
    this.translate.addLangs(Locales);
  }
}

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }
