import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  // DOMAIN
  stateView: string = STATE_RENDER_APP.INIT;
  adminHubApp: string = environment.ADMIN_HUB_URL;

  // DEV
  //stateView: string = STATE_RENDER_APP.APP;
  user: any;

  constructor(
    private primeNgConfig: PrimeNGConfig,
    private translateService: TranslateService,
  ) {
    window.addEventListener('message', this.handleReceiveMessage);
  }

  ngOnInit() {
    this.checkSmallScreen();
    window.addEventListener('resize', () => this.checkSmallScreen());

    this.translateService.onLangChange
      .pipe(
        switchMap((event: LangChangeEvent) => {
          const res = this.translateService.getParsedResult(
            event.translations,
            'primeng',
          );
          return of(res);
        }),
      )
      .subscribe((res: any) => {
        const translations = this.applyPrimeNgTranslations(res);
        this.primeNgConfig.setTranslation(translations);
      });
  }

  applyPrimeNgTranslations(translations: any): any {
    switch (typeof translations) {
      case 'function':
        return translations();
      case 'object':
        if (Array.isArray(translations)) {
          return translations.map((t) => this.applyPrimeNgTranslations(t));
        }
        for (const key in translations) {
          if (key) {
            translations[key] = this.applyPrimeNgTranslations(
              translations[key],
            );
          }
        }
        return translations;
      default:
        return translations;
    }
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  handleReceiveMessage = (e: MessageEvent) => {
    if (e?.data?.type === 'authorized' && e.origin === this.adminHubApp) {
      const accessTokenData = e.data?.token?.accessToken;
      const profileData = JSON.parse(e.data?.profile);
      if (accessTokenData && profileData) {
        localStorage.setItem('accessToken', accessTokenData);
        localStorage.setItem('auth', JSON.stringify(profileData));
        localStorage.setItem(environment.USER_PROFILE, e.data.profile);
        this.user = profileData;
        this.user.avatarURL = `${environment.BE_URL}storage/files/web/${this.user?.avatar_thumbnail_url}.webp`;
        this.stateView = STATE_RENDER_APP.APP;
      } else {
        window.location.href = `${environment.ADMIN_HUB_URL}/login?r=${environment.FE_URL}`; // => COMMENT WHILE DEV
      }
    } else if (e.data.type === 'unauthorized') {
      window.location.href = `${environment.ADMIN_HUB_URL}/login?r=${environment.FE_URL}`; // => COMMENT WHILE DEV
    }
  };

  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }
}
