import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetApiService {
  chatApi: string = `${environment.baseUrl}/chat`;

  constructor(private httpClient: HttpClient) {}

  createHeader() {
    const header = new HttpHeaders({
      'content-type': 'application/json',
    });
    return header;
  }

  getAdminChatSessionsCount(status: string, module: string) {
    const options = { headers: this.createHeader() };
    const chatGeneralApi = `${this.chatApi}/sessions/support/status/${status}/count?module=${module}`;
    return this.httpClient.get(chatGeneralApi, options);
  }

  carOTAOrderUrl: string = `${environment.BE_URL}car-rental-order/`;
  getAdminHelpdesk(
    tab: string[],
    filters?: string,
    pageNum?: number,
    pageSize?: number,
    sort?: string,
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const adminHelpdesk = `${this.carOTAOrderUrl}`;
    return this.httpClient.get(
      adminHelpdesk +
        'admin/helpdesk' +
        '?' +
        tab.map((t) => `tabs=${encodeURIComponent(t)}`).join('&') +
        (filters ? '&filter=' + encodeURIComponent(filters) : '') +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        '&sort=' +
        sort,
      options,
    );
  }

  getAdminExportOrders(
    tab: string,
    filters?: string,
    pageNum?: number,
    pageSize?: number,
    sort?: string,
  ): Observable<Blob> {
    const options = {
      headers: this.createHeader(),
      responseType: 'blob' as 'json',
    };
    const adminHelpdesk = `${this.carOTAOrderUrl}`;
    return this.httpClient.get<Blob>(
      adminHelpdesk +
        'admin/export/orders' +
        '?tab=' +
        tab +
        (filters ? '&filter=' + encodeURIComponent(filters) : '') +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        '&sort=' +
        sort,
      options,
    );
  }
}
