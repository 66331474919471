<div class="h-full flex gap-2 justify-between items-center px-6 bg-blue-600">
  <span class="flex items-center gap-2">
    <i
      *ngIf="isSmallScreen"
      class="pi pi-bars p-1 cursor-pointer hover:text-blue-500 text-white"
      (click)="openSidebar.emit(true)"
    ></i>
    <a [href]="adminHubURL" class="flex items-center gap-2 cursor-pointer">
      <img
        class="inline-block w-auto h-[1.875rem] object-contain object-center"
        [src]="appLogo"
        alt="AppLogo"
      />
    </a>
    <h2 class="text-xl font-bold text-white">
      {{ environment.APP_TITLE }}
    </h2>
    <div class="text-white">
      {{ "component.header.title" | translate }}
    </div>
  </span>

  <div class="flex items-center gap-4 lg:gap-6">
    <app-flags-dropdown
      (languageChange)="changeLanguage($event)"
    ></app-flags-dropdown>
    <!-- <a
      *ngIf="environment.SOCCHAT_VISIBLE"
      aria-label="admin-support"
      [routerLink]="['/', adminSupportUrl]"
      class="font-medium text-white rounded-md text-md flex"
      routerLinkActive="text-primary-600 font-semibold"
      [pTooltip]="'admin-support.title' | translate"
      tooltipPosition="bottom"
      tooltipStyleClass="t-tooltip-light"
    >
      <i
        pBadge
        [value]="chatBadgeCount"
        severity="danger"
        class="t-bell-noti-header sctr-icon-message-chat-square cursor-pointer text-2xl/none text-white"
      ></i>
    </a> -->
    <!-- <div class="relative cursor-pointer">
      <i class="pi pi-bell !text-xl text-white"></i>
      <span
        class="absolute w-2 h-2 bg-orange-dark-500 rounded-full left-3 top-[0.125rem]"
      ></span>
    </div> -->

    <!-- (click)="menu.toggle($event)" -->
    <div class="flex items-center gap-3 cursor-pointer">
      <img
        class="inline-block w-[1.875rem] h-[1.875rem] object-contain object-center rounded-full"
        [src]="user?.avatarURL"
        onerror="this.src='assets/imgs/default/avatar.webp'"
        (click)="menu.toggle($event)"
      />
      <div
        *ngIf="user?.full_name"
        class="grid text-sm font-semibold text-white"
      >
        <span>
          {{ user?.full_name }}
        </span>
      </div>
    </div>

    <p-overlayPanel #menu>
      <div class="-mx-4 -my-3">
        <div
          class="flex items-center gap-2 text-orange-dark-600 min-w-[200px] px-4 py-2 cursor-pointer hover:bg-gray-100"
          (click)="handleSignOut()"
        >
          <i class="sctr-icon-log-out-01"></i>
          <div class="text-sm">
            {{ "component.header.logout" | translate }}
          </div>
        </div>
      </div>
    </p-overlayPanel>
  </div>
</div>
