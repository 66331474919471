import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppRoutes } from 'src/app/core/enum/routes.enum';
import { AuthService } from 'src/app/core/services/auth.service';
// import { ChatService } from 'src/app/core/services/chat.service';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { GetApiService } from 'src/app/core/services/get-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isSmallScreen: boolean = false;
  @Input() user: any;
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();
  environment = environment;
  translator = inject(CustomTranslateService);
  sanitizer = inject(DomSanitizer);
  adminHubURL = environment.ADMIN_HUB_URL;
  iframeUrl: SafeResourceUrl;
  appLogo = `../assets/imgs/logo/${environment.APP_LOGO}.svg`;
  adminSupportUrl = AppRoutes.ADMIN_SUPPORT;
  chatBadgeCount = '0';
  // chatService = inject(ChatService);
  getApiService = inject(GetApiService);
  adminHubAppConnectUrl: string = environment.ADMIN_HUB_URL + '/app-connect';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.IFRAME_CONNECT_URL,
    );
    // this.getChatPendingCount();
    // this.chatService.pendingSessionChanges.subscribe((value) => {
    //   if (value) {
    //     this.getChatPendingCount();
    //   }
    // });
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };

  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }

  handleSignOut = () => {
    const userProfileId = JSON.parse(
      localStorage.getItem(environment.USER_PROFILE) || '{}',
    )?.id;
    const currentDeviceInfo = JSON.parse(
      localStorage.getItem('device_sign_in') || '{}',
    );

    this.connectPMServer();
    this.authService
      .logoutv2({
        user_id: userProfileId,
        device: currentDeviceInfo?.device,
        device_id: currentDeviceInfo?.device_id,
        device_type: currentDeviceInfo?.device_type,
      })
      .subscribe();
    setTimeout(() => {
      localStorage.clear();
      window.location.href = `${environment.ADMIN_HUB_URL}/login?r=${environment.FE_URL}`;
      // this.router.navigate(['login']);
    }, 2000);
  };

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.adminHubAppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'disconnect' }, '*');
    };
    ifr.onload = connect;
  }

  getChatPendingCount() {
    this.getApiService
      .getAdminChatSessionsCount('PENDING', environment.CHAT_MODULE)
      .subscribe({
        next: (res: any) => {
          this.chatBadgeCount = String(res?.data);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
}
