export const PREFIX = {
  API_V1: 'api/v1',
  AVATAR_DIR: 'sso-service/static/images/avatars',
  DEV: 'dev',
  PROD: 'production',
  QA: 'qa',
  LOCAL: 'local',
};

export const SERVICES = {
  STORAGE: 'storage',
  STORAGE_STREAMING: 'storage-streaming',
  SHIPMENT: 'shipment',
  ANALYTIC: 'shop-analytic',
  CAR_RENTAL_OTA: 'car-rental-ota',
  CAR_RENTAL_ORDER: 'car-rental-order',
  ECOMMERCE_FINANCE: 'ecommerce-finance',
  ECOMMERCE_WALLET: 'ecommerce-wallet',
  CAR_RENTAL: 'car-rental',
  REGIONAL: 'regional',
  MAP_LOCATION: 'map-location',
};
